Fancybox.bind("[data-fancybox]", {
	parentEl: document.forms[0], // Element containing main structure
});
document.addEventListener("DOMContentLoaded", () => {
	mappingFunc();
	hideElement();
	swiperInit();
	setBackground();
	counterAnimate();
	generalFnc();
	filterDisplay();
});

window.addEventListener("resize", function () {
	let width = this.window.innerWidth;
	if (width <= 1200.1) {
		mappingFunc();
		hideElement();
	}
});
window.addEventListener("scroll", function () {
	const nav = document.querySelector(".side-nav-tool");
	if (
		document.body.scrollTop > $(window).height() ||
		document.documentElement.scrollTop > $(window).height()
	) {
		nav.classList.add("show");
	} else {
		nav.classList.remove("show");
	}
	if ($(window).width() > 1200.1) {
		if (
			document.body.scrollTop > 100 ||
			document.documentElement.scrollTop > 100
		) {
			$("header").addClass("minimize");
		} else {
			$("header").removeClass("minimize");
		}
	}
});

function generalFnc() {
	//Toggle Javascript code

	const bodyWrap = document.querySelector("body");
	const menuToggle = document.querySelector(".site-menu-toggle");
	const hamburger = document.querySelector(".hamburger");
	const menuMobile = document.querySelector(".mobile-nav-wrap");
	const searchToggle = document.querySelector(".search-toggle");
	const search = document.querySelector(".searchbox");
	const backTop = document.querySelector(".back-to-top");

	//Search Toggle

	searchToggle.addEventListener("click", function (event) {
		search.classList.toggle("open");
	});

	backTop.addEventListener("click", (event) => {
		event.preventDefault();
		$("html, body").animate({ scrollTop: 0 }, "300");
	});
	// Menu function

	menuToggle.addEventListener("click", () => {
		menuMobile.classList.toggle("is-open");
		hamburger.classList.toggle("is-active");
		bodyWrap.classList.toggle("overlay-bg");
	});

	if ($(window).width() < 1200.1) {
		$(".drop-down .title em").on("click", function () {
			if ($(this).parents(".drop-down").hasClass("is-open")) {
				$(".drop-down .nav-sub").slideUp();
				$(".drop-down").removeClass("is-open");
			} else {
				$(".drop-down .nav-sub").slideUp();
				$(".drop-down").removeClass("is-open");
				$(this).parent().next().slideDown();
				$(this).parents(".drop-down").addClass("is-open");
			}
		});
		$(".tertiary-menu-toggle").on("click", function () {
			$(".nav-tertiary-menu").slideToggle();
		});
	}

	//Commnet append

	if ($("body").hasClass("product-detail-page")) {
		if ($(".commentpanel").length) {
			$(".commentpanel").appendTo(".product-rating-wrap");
		}
		setTimeout(() => {
			if ($(".product-detail-title").length) {
				let title = $(".product-detail-title").text();
				$(".form-product-title").text(title);
			}
		}, 1000);
	}
	if ($("body").hasClass("scoll-pad-page")) {
		$("html").css({ "scroll-padding-top": "100px" });
	}

	document.addEventListener("click", (event) => {
		if (!event.target.closest(".search-toggle, .searchbox")) {
			search.classList.remove("open");
		}
		if (!event.target.closest(".mobile-nav-wrap, .site-menu-toggle")) {
			menuMobile.classList.remove("is-open");
			bodyWrap.classList.remove("overlay-bg");
			hamburger.classList.remove("is-active");
		}
	});
	//Tab function

	$(".tab-nav li a").on("click", function () {
		$(this).parents(".tab-nav").find("li").removeClass("active");
		$(this).parents("li").addClass("active");

		var display = $(this).attr("data-type");
		$(this).parents("section").find(".tab-item").removeClass("active");
		$("#" + display).addClass("active");
	});
	$(".tab-nav-item li a").on("click", function () {
		$(this).parents(".tab-nav-item").find("li").removeClass("active");
		$(this).parents("li").addClass("active");

		var display = $(this).attr("data-type");
		$(this).parents("section").find(".tab-item").removeClass("active");
		$("." + display).addClass("active");
	});
}
function filterDisplay() {
	$(".filter-display-wrap").each(function () {
		let x = 5;
		$(this).find(".recruit-item ").slice(0, 6).show().css({
			display: "flex",
		});
		let size = $(this).find(".recruit-item ").length;
		if (size < x || size == x) {
			$(this).find(".ajax-btn-wrap").addClass("disble-btn");
		}
		$(".filter-display-wrap")
			.find(".btn-secondary")
			.on("click", function (e) {
				e.preventDefault();
				x = x + 3;
				$(this)
					.parents(".filter-display-wrap")
					.find(".recruit-item ")
					.slice(0, x)
					.slideDown()
					.css({
						display: "flex",
					});
				if (x >= size || size < x) {
					$(this).slideUp();
				}
			});
	});
}

function swiperInit() {
	var primarySwiper = new Swiper(".primary-banner .swiper", {
		// Optional parameters
		slidesPerView: 1,
		observer: true,
		observeParents: true,
		preventInteractionOnTransition: true,
		speed: 1205,
		autoplay: false,
		lazy: {
			loadPrevNext: true,
		},
		loop: true,
		navigation: {
			nextEl: ".primary-banner .nav-arrow-next",
			prevEl: ".primary-banner .nav-arrow-prev",
		},
		pagination: {
			el: ".primary-banner  .swiper-pagination",
			type: "progressbar",
		},
	});

	let timer = null;

	primarySwiper.on("slideChangeTransitionEnd", function () {
		if (timer) {
			clearTimeout(timer); //cancel the previous timer.
			timer = null;
		}
		const currentSlide = primarySwiper.slides[primarySwiper.activeIndex];
		const currentSlideHasVideo = currentSlide.querySelector("video");
		if (currentSlideHasVideo) {
			const videoElement = currentSlide.querySelector("video");
			videoElement.play();
			videoElement.addEventListener("ended", function () {
				primarySwiper.slideToClosest();
			});
		} else {
			timer = setTimeout(() => {
				primarySwiper.slideNext();
			}, 3000);
		}
	});

	const primaryBannerVideo = document.querySelectorAll(
		".primary-banner-video"
	);
	primaryBannerVideo.forEach(function (videoElement) {
		videoElement.addEventListener("ended", function () {
			primarySwiper.slideNext();
		});
		videoElement.addEventListener("click", function (event) {
			if (this.paused) {
				this.play();
				this.parentNode.classList.remove("pause");
			} else {
				this.pause();
				this.parentNode.classList.add("pause");
			}
		});
	});

	var homeMachine = new Swiper(".home-machine .swiper", {
		spaceBetween: 0,
		speed: 1205,
		autoplay: {
			delay: 3000,
		},
		lazy: {
			loadPrevNext: true,
		},
		breakpoints: {
			320: {
				slidesPerView: 1,
			},
			576: {
				slidesPerView: 2,
			},
			768: {
				slidesPerView: 3,
			},
		},
		navigation: {
			nextEl: ".home-machine .next",
			prevEl: ".home-machine .prev",
		},
	});

	var swiperGrid = new Swiper(".grid-swiper .swiper", {
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
		lazy: {
			loadPrevNext: true,
		},
		observer: true,
		spaceBetween: 30,
		observeParents: true,
		slidesPerView: 4,
		grid: {
			rows: 2,
		},
		pagination: {
			el: ".swiper-pagination",
			clickable: true,
		},
	});

	var homePrimarySwiper = new Swiper(".primary-banner .swiper", {
		// Optional parameters
		slidesPerView: 1,
		speed: 1205,
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
		lazy: {
			loadPrevNext: true,
		},
		navigation: {
			nextEl: ".primary-banner .next",
			prevEl: ".primary-banner .prev",
		},
	});
	var partnerSwiper = new Swiper(".partner-swiper .swiper", {
		// Optional parameters
		speed: 1205,
		lazy: {
			loadPrevNext: true,
		},
		breakpoints: {
			320: {
				slidesPerView: 2,
			},
			576: {
				slidesPerView: 3,
			},
			768: {
				slidesPerView: 4,
			},
			1024: {
				slidesPerView: 5,
			},
		},
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
		navigation: {
			nextEl: ".partner-swiper .next",
			prevEl: ".partner-swiper .prev",
		},
	});

	var historyThumb = new Swiper(" .swiper-history-thumb .swiper", {
		spaceBetween: 20,
		breakpoints: {
			200: {
				slidesPerView: 2,
			},
			768: {
				slidesPerView: 3,
			},
			1024: {
				slidesPerView: 5,
			},
		},
		speed: 300,
		noSwiping: true,
		autoplay: {
			delay: 5000,
		},
		lazy: {
			loadPrevNext: true,
		},
	});
	var historyMain = new Swiper(".swiper-history-main .swiper", {
		spaceBetween: 10,
		centeredSlides: true,
		speed: 1205,
		autoplay: {
			delay: 3000,
		},
		lazy: {
			loadPrevNext: true,
		},
		navigation: {
			nextEl: ".swiper-history-thumb .next",
			prevEl: ".swiper-history-thumb .prev",
		},
		thumbs: {
			swiper: historyThumb,
		},
	});
	let single = $(".single-swiper .swiper");
	for (let index = 0; index < single.length; index++) {
		let $this = $(single[index]);
		$this.addClass("single-" + index);
		$this
			.parents(".single-swiper")
			.find(".prev")
			.addClass("prev-single-" + index);
		$this
			.parents(".single-swiper")
			.find(".next")
			.addClass("next-single-" + index);
		$this
			.parents(".single-swiper")
			.find(".swiper-pagination")
			.addClass("single-pagination-" + index);
		var swiper = new Swiper(".single-" + index, {
			preventInteractionOnTransition: true,
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			loop: false,
			speed: 1240,
			spaceBetween: 30,
			slidesPerView: 1,
			navigation: {
				nextEl: ".next-single-" + index,
				prevEl: ".prev-single-" + index,
			},
			pagination: {
				el: ".single-pagination-" + index,
				type: "bullets",
				clickable: true,
			},
		});
	}
	let double = $(".double-swiper .swiper");
	for (let index = 0; index < double.length; index++) {
		let $this = $(double[index]);
		$this.addClass("double-" + index);
		$this
			.parents(".double-swiper")
			.find(".prev")
			.addClass("prev-double-" + index);
		$this
			.parents(".double-swiper")
			.find(".next")
			.addClass("next-double-" + index);
		$this
			.parents(".double-swiper")
			.find(".swiper-pagination")
			.addClass("double-pagination-" + index);
		var swiper = new Swiper(".double-" + index, {
			preventInteractionOnTransition: true,
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			loop: false,
			speed: 1240,
			breakpoints: {
				320: {
					spaceBetween: 10,
					slidesPerView: 1,
				},
				400: {
					spaceBetween: 20,
					slidesPerView: 2,
				},
			},
			navigation: {
				nextEl: ".next-double-" + index,
				prevEl: ".prev-double-" + index,
			},
			pagination: {
				el: ".double-pagination-" + index,
				type: "bullets",
				clickable: true,
			},
		});
	}
	let trinity = $(".trinity-swiper .swiper");
	for (let index = 0; index < trinity.length; index++) {
		let $this = $(trinity[index]);
		$this.addClass("trinity-" + index);
		$this
			.parents(".trinity-swiper")
			.find(".prev")
			.addClass("prev-trinity-" + index);
		$this
			.parents(".trinity-swiper")
			.find(".next")
			.addClass("next-trinity-" + index);
		$this
			.parents(".trinity-swiper")
			.find(".swiper-pagination")
			.addClass("trinity-pagination-" + index);
		var swiper = new Swiper(".trinity-" + index, {
			preventInteractionOnTransition: true,
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			loop: false,
			speed: 1240,
			breakpoints: {
				320: {
					spaceBetween: 10,
					slidesPerView: 1,
				},
				400: {
					spaceBetween: 20,
					slidesPerView: 2,
				},
				768: {
					spaceBetween: 30,
					slidesPerView: 3,
				},
			},
			navigation: {
				nextEl: ".next-trinity-" + index,
				prevEl: ".prev-trinity-" + index,
			},
			pagination: {
				el: ".trinity-pagination-" + index,
				type: "bullets",
				clickable: true,
			},
		});
	}
	let four = $(".four-swiper .swiper");
	for (let index = 0; index < four.length; index++) {
		let $this = $(four[index]);
		$this.addClass("four-" + index);
		$this
			.parents(".four-swiper")
			.find(".prev")
			.addClass("prev-four-" + index);
		$this
			.parents(".four-swiper")
			.find(".next")
			.addClass("next-four-" + index);
		$this
			.parents(".four-swiper")
			.find(".swiper-pagination")
			.addClass("four-pagination-" + index);
		var swiper = new Swiper(".four-" + index, {
			preventInteractionOnTransition: true,
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			loop: false,
			speed: 1240,
			breakpoints: {
				320: {
					spaceBetween: 10,
					slidesPerView: 1,
				},
				400: {
					spaceBetween: 20,
					slidesPerView: 2,
				},
				576: {
					spaceBetween: 20,
					slidesPerView: 3,
				},
				1024: {
					spaceBetween: 20,
					slidesPerView: 4,
				},
			},
			navigation: {
				nextEl: ".next-four-" + index,
				prevEl: ".prev-four-" + index,
			},
			pagination: {
				el: ".four-pagination-" + index,
				type: "bullets",
				clickable: true,
			},
		});
	}

	var productThumbs = new Swiper(".swiper-product-thumb  .swiper", {
		spaceBetween: 8,
		breakpointsInverse: true,
		breakpoints: {
			320: {
				slidesPerView: 3,
			},
			768.1: {
				spaceBetween: 15,
				slidesPerView: 5,
			},
			1024.1: {
				spaceBetween: 15,
				slidesPerView: 3,
			},
			1600.1: {
				spaceBetween: 25,
				slidesPerView: 4,
			},
		},
		watchSlidesVisibility: true,
		watchSlidesProgress: true,
	});
	var productMain = new Swiper(".swiper-product-main .swiper", {
		slidesPerView: 1,
		centeredSlides: true,
		lazy: {
			loadPrevNext: true,
		},
		autoplay: {
			delay: 3000,
		},
		speed: 750,
		loop: false,
		navigation: {
			nextEl: ".product-swiper-wrap  .nav-next",
			prevEl: ".product-swiper-wrap  .nav-prev",
		},
		thumbs: {
			swiper: productThumbs,
		},
	});
}

// Side
function setBackground() {
	const elements = document.querySelectorAll("[setBackground]");
	elements.forEach((element) => {
		element.style.cssText = `
		background-image: url(${element.getAttribute("setBackground")});
		background-size: cover;
		background-position: center;
	  `;
	});
}
function counterAnimate() {
	const counterItem = document.querySelectorAll(".counter");
	if (counterItem.length) {
		const counterUp = window.counterUp.default;
		const callback = (entries) => {
			entries.forEach((entry) => {
				const el = entry.target;
				if (
					entry.isIntersecting &&
					!el.classList.contains("is-visible")
				) {
					counterUp(el, {
						duration: 2000,
						delay: 100,
					});
					el.classList.add("is-visible");
				}
			});
		};
		const IO = new IntersectionObserver(callback, { threshold: 1 });

		counterItem.forEach((counter) => {
			IO.observe(counter);
		});
	}
}

function mappingFunc() {
	new MappingListener({
		selector: ".nav-primary-menu",
		mobileWrapper: ".mobile-nav-wrap",
		mobileMethod: "appendTo",
		desktopWrapper: ".nav-brand ",
		desktopMethod: "insertAfter",
		breakpoint: 1200.1,
	}).watch();
}
function hideElement() {
	if ($(window).width() < 1200.1) {
		const [menu] = [".nav-primary-menu"].map((selector) =>
			document.querySelector(selector)
		);

		[menu].forEach((el) => (el.style.display = "flex"));
	}
}
